import React from 'react'
import { DateRange, dateRangeToQuery, dateRangeFromQuery } from 'dateRanges'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { useSelector } from 'react-redux'
import { getListOrderData } from '../redux'
import SearchBar from 'uiComponents/search/searchBar'
import './searchAndDate.scss'
import { isLocalStorageAvailable } from 'utils/storage'

interface OrderFiltersProps extends WithNavigationProps<BaseRouteParams> {}

const OrderFilters: React.FC<OrderFiltersProps> = ({ navigation }) => {
    const query = navigation.query()
    const status = useSelector(getListOrderData)

    const onDateRangeChanged = (range: DateRange) => {
        navigation.addQueryWithReplace({ ...dateRangeToQuery(range), page: '1' })
    }

    const onDateRangeTypeChanged = (rangeType: DateRangeType) => {
        navigation.addQueryWithReplace({ dateRangeType: rangeType, page: '1' })
    }

    const onSearchByChange = (searchBy: string) => {
        if (isLocalStorageAvailable()) {
            localStorage.setItem('orders_search_by', searchBy)
        }
    }

    const defaultSearchBy =
        query.searchBy || (isLocalStorageAvailable() ? localStorage.getItem('orders_search_by') : 'email') || 'email'
    const searchByOptions = [
        { value: 'email', name: 'Email' },
        { value: 'id', name: 'Order number/ID' },
        { value: 'barcode', name: 'Barcode' },
        { value: 'discount_code', name: 'Discount code' },
    ]

    const dateRange = dateRangeFromQuery(query, 'today')
    const dateRangeType = query.dateRangeType === 'visit' ? 'visit' : 'sale'

    return (
        <div className="order-filters-container">
            <div className="left-container">
                <div className="search-container">
                    <SearchBar
                        searchByOptions={searchByOptions}
                        replaceMessages={() => {}}
                        removeAllMessages={() => {}}
                        selectWidth="11em"
                        className="order-list-search-bar"
                        defaultSearchBy={defaultSearchBy}
                        onSearchByChange={onSearchByChange}
                        searchOnEnter={true}
                        showClearButton
                        autoSearchByPatterns={[
                            [/@/, 'email'],
                            [/^\d{9}$/, 'id'],
                            [/^\d{14}$/, 'barcode'],
                        ]}
                    />
                    <div className="search-bar-subtitle">
                        {!status.isLoading && query.search
                            ? `Showing results for ${
                                  searchByOptions.find(({ value }) => value === query.searchBy)?.name
                              } "${query.search}"`
                            : ''}
                    </div>
                </div>
            </div>
            <div className="right-container" id="orders-filter">
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    dateRangeTypeDisabled={status.isLoading}
                    dateRangeType={dateRangeType}
                    onDateRangeTypeChange={onDateRangeTypeChanged}
                    allowFutureDateSelection
                />
            </div>
        </div>
    )
}

export default withNavigation(OrderFilters)
