import React from 'react'
import Money from 'uiComponents/money'
import { IOrderItems, OrderTicketStatusKeys } from 'orders/schema'
import RedeemedOn from 'orders/orders/ordersTable/orderDetails/redeemedOn'
import { Link } from 'react-router-dom'
import OrderItemStatus from 'orders/orders/ordersTable/orderDetails/orderItemStatus'
import ItemSection from './components/itemSection'
import SectionDataGrid from './components/sectionDataGrid'
import './styles.scss'
import ValidTo from 'orders/orders/commons/validTo'
import { ReplaceMessagesFunc } from 'hocs'
import { convertTimeFormat, DateFormats, formatISOString } from 'utils/dates'

const ItemDetailsSection = ({
    item,
    accountSlug,
    isItemSelected,
    orderId,
    replaceMessages,
    hideMessage,
    isItemCanceledRefunded,
    shouldShowPricesWithoutTax,
}: {
    item: IOrderItems
    accountSlug: string
    isItemSelected: boolean
    orderId: string
    replaceMessages: ReplaceMessagesFunc
    hideMessage: (id: string) => void
    isItemCanceledRefunded: boolean
    shouldShowPricesWithoutTax: boolean
}) => {
    const detailsData = React.useMemo(
        () => [
            { name: 'Barcode', value: item.barcode?.barcode },
            {
                name: 'Product ID',
                value: (
                    <Link
                        title={item.productNumericId.toString()}
                        to={`/account/${accountSlug}/products/crud/${item.productId}`}
                        target="_blank"
                    >
                        {item.productNumericId}
                    </Link>
                ),
            },
            { name: 'Redeemed on', value: <RedeemedOn value={item.barcode?.redeemed ?? undefined} /> },
            {
                name: 'Visit date',
                value: item.visitDate ? formatISOString(item.visitDate, DateFormats.LONG_DATE) : '-',
            },
            {
                name: 'Amount (excl. tax)',
                value: <Money amount={item.priceExclTax} accountSlug={accountSlug} />,
                hide: !shouldShowPricesWithoutTax,
            },
            { name: 'Amount', value: <Money amount={item.priceInclTax} accountSlug={accountSlug} /> },
            {
                name: 'Valid from',
                value: item.validFrom ? formatISOString(item.validFrom, DateFormats.LONG_DATE) : '-',
            },
            {
                name: 'Valid to',
                value: item.validTo ? (
                    <ValidTo
                        status={item.barcode?.status ?? ''}
                        orderItem={item}
                        orderId={orderId}
                        replaceMessages={replaceMessages}
                        hideMessage={hideMessage}
                    />
                ) : (
                    '-'
                ),
            },
            {
                name: 'Visit time',
                value: item.visitTime ? convertTimeFormat(item.visitTime, 'HH:mm:ss', DateFormats.SHORT_TIME) : '-',
            },
            {
                name: 'Status',
                value: <OrderItemStatus value={item.barcode?.status as OrderTicketStatusKeys} />,
            },
        ],
        [item, accountSlug],
    )

    return (
        <ItemSection
            sectionName="Details"
            isItemSelected={isItemSelected}
            isItemCanceledRefunded={isItemCanceledRefunded}
        >
            <SectionDataGrid dataItems={detailsData} />
        </ItemSection>
    )
}

export default ItemDetailsSection
